/*global fbq*/
import React from "react";
import posthog from "posthog-js";
import {
  PostHogProvider,
  useFeatureFlagVariantKey as posthogUseFeatureFlagVariantKey,
} from "posthog-js/react";

// If you set this to true, but it is still not working for you locally you
// might have an add blocker that is stopping requests to posthog. Also change
// what is passed into init from gatsby-browser.js
let posthogEnabled = false;

// The logic for "enabled" need to be in `gatsby-browser`, so this init function
// allowed it to be passed in
export function init(enabled: boolean) {
  posthogEnabled = enabled;

  if (posthogEnabled) {
    posthog.init("EkCFWafRUDttPCKNmaliCewZy1-xv-CyQdWxnQvkvBU", {
      api_host: "https://app.posthog.com",
      autocapture: true,
      // pageviews will automatically be captured in `onRouteUpdate`
      capture_pageview: false,
    });
  }
}

export function capture(eventName: string, properties?: Record<string, any>) {
  if (posthogEnabled) {
    posthog.capture(eventName, properties);
  }
}

export function identify(backendId) {
  if (posthogEnabled && backendId) {
    console.info(`Setting posthogId=${backendId}`);
    posthog.identify(backendId);
  }
}

type FeatureVariants = {
  interactive_proposal_7:
    | "control"
    | "control_new_flow"
    | "interactive_high_price_new_flow"
    | "interactive_low_price";
};

const localhostOverrides: Record<keyof FeatureVariants, string> = {
  interactive_proposal_7: "control_new_flow",
};

// Users in these variants will be able to see the interactive proposal.
export const interactiveProposalGroupVariants = [
  "interactive_high_price_new_flow",
  "interactive_low_price",
];

// Users in these variants will be added a caa_nofollow tag
export const caaNoFollowGroupVariants = [
  "control_new_flow",
  "interactive_high_price_new_flow",
  "interactive_low_price",
];

export function useBrightFeatureFlag<Flag extends keyof FeatureVariants>(
  flag: Flag,
): FeatureVariants[Flag] | undefined {
  if (posthogEnabled === false) {
    return localhostOverrides[flag] as any;
  }

  return posthogUseFeatureFlagVariantKey(flag) as any;
}

export function persistUtmTags() {
  const s = new URLSearchParams(window.location.search);
  const utms = {};
  const utmKeys = [];
  s.forEach((_value, key, _params) => {
    if (key.indexOf("utm_") === 0) {
      utmKeys.push(key);
      utms[key] = s.get(key);
    }
  });

  // we have some UTM tags to save into a cookie, save them and remove them from the URL
  if (utmKeys.length > 0) {
    const cookieDate = new Date();
    // add 1 day expiration
    cookieDate.setDate(cookieDate.getDate() + 1);
    const cookieValue = encodeURIComponent(JSON.stringify(utms));
    document.cookie = `bright_utms=${cookieValue}; expires=${cookieDate}; SameSite=Strict;`;
  }
}

export function fbTrack(a, b) {
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    fbq(a, b);
  }
}

export const AnalyticsProvider = ({ children }) => {
  return (
    <PostHogProvider client={posthogEnabled ? posthog : null}>
      {children}
    </PostHogProvider>
  );
};
